<template>
    <div>
        <div class="card events-card mb-3">
            <div class="is-flex is-justify-content-space-between px-2 is-color-palete-blue-1">
                <div>
                    <p class="card-header-title is-size-6 pl-1 py-1 has-text-white">{{$t("delivered")}}</p>
                </div>
                <div class="is-flex is-align-items-center is-justify-content-flex-end">
                    <!-- <transition name="fade" mode="out-in">
                        <div @click.prevent="$parent.show_search_1 = !$parent.show_search_1" key="3" v-if="!$parent.show_search_1" class="is-clickable">
                            <i class="fas fa-search"></i>
                        </div>
                        <div  key="1" v-if="$parent.show_search_1" class="is-flex is-align-items-center">
                            <div class="field has-addons mb-0">
                            <div class="control">
                                <input class="input is-small" type="text" :placeholder="$t('search')">
                            </div>
                            <div class="control">
                                <a class="button is-info is-small">
                                <i class="fas fa-search"></i>
                                </a>
                            </div>
                            </div>
                            <button @click.prevent="$parent.show_search_1 = !$parent.show_search_1" class="delete is-small ml-1"></button>
                        </div>
                    </transition> -->
                    <!-- <i @click.prevent="$parent.getMyModuleData('delivered')" class="fas fa-sync-alt is-pulled-right is-clickable ml-3"></i> -->
                </div>
            </div>
            <div class="card-table">
                <div class="content trucks-table-height custom_scrollbar-1">
                    <table v-if="get_dashboard_returning_trucks.length > 0" class="table is-striped dashboard-table is-narrow is-fullwidth is-size-7">
                        <thead class="th-border-blue-1">
                            <th>{{$t("truckname")}}</th>
                            <th>{{$t("project_name")}}</th>
                            <th>{{$t("loading_schedule")}}</th>
                        </thead>
                        <tbody>
                            <tr v-for="(nf, index) in get_dashboard_returning_trucks" :key="index">
                                <td>{{nf.truck_name}}</td>
                                <td>{{nf.name}}</td> 
                                <td>{{nf.loading_schedule | customdate}}</td>
                            </tr>
                            <!-- <tr>
                                <td>1st June </td>
                                <td>1-00xy Arena Panels Sommerbiathlom Wiesbaden</td>
                                <td>1st June 16.00 h</td>
                            </tr> -->
                        </tbody>
                    </table>
                    <small v-else class="ml-2 has-text-grey-light" >
                        No data
                    </small>
                </div>
            </div>
            <!-- <footer v-if=" get_dashboard_returning_trucks.length && this.$parent.modules_data.my_loading_trucks.filter(t => t.event_type == 'delivered').length != get_dashboard_returning_trucks.length"
             class="card-footer p-1 ">
                <a @click.prevent="toggle_limit()" href="#" class="card-footer-item p-0 has-text-white">{{$t("load_more")}}</a>
            </footer> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            limit: 6,
            default_limit: 6
        }
    },
    computed: {
        get_dashboard_returning_trucks(){ 
            return this.$parent.modules_data.my_loading_trucks.filter(t => t.event_type == 'delivered').filter(a => {
                if(this.$parent.selected_project_id == a.project_id){
                    console.table(this.$parent.selected_project_id, a.project_id)
                    return a
                }
                else if(!this.$parent.selected_project_id) return a
            }) 
            .filter((obj) =>{
                if(this.$parent.tetris_search_text != ''){
                    return JSON.stringify(obj).toLowerCase().includes(this.$parent.tetris_search_text.toLowerCase())
                } else return obj
            })
        },
    },
    methods: {
        toggle_limit(){
            this.limit =  this.limit + this.default_limit
        },
    }
}
</script>

<style>


</style>